body.nightmode {
  background-color: #252525;
  transition: background-color 500ms;
  color: white !important;
}

body {
  transition: background-color 500ms;
}

.projects a {
  text-decoration: underline;
}