body {
  background-color: white;
  color: black;
}

.jumbo {
  background-size: cover;
  font-family: 'Poppins', sans-serif;
  font-style: lighter;
  height: 90vh;
  max-width: 1300px;
}

.display-4 {
  line-height: 1.2;
}

.mark{
  background-color: white !important;
}

.jumbotron1 {
  height: 83vh;
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
  margin-right: 10px;
}


/* H1 Home Mobile Size for Jumbotron   */
.jumbotron1 h1 {
  font-size: 2.5rem;
}

/* Media queries for Responsiveness in Jumbotron */

@media (min-width: 120px) and (max-width: 450px){
  .jumbotron1 h1 {
    font-size: 2.6rem;
  }
  .jumbotron1  {
    margin-top: 200px;
  }
}

/* ipad size */
@media (min-width: 768px) and (min-width: 769px) {
  .jumbotron1 h1 {
font-size: 3rem;
}
}



@media (min-width: 1200px){
  .jumbotron1 h1 {
font-size: 4.25rem;
}
}

/* End of JUMBOTRON1 */

.jumbotron2 h1 {
  font-size: 4rem;
  padding-top: 390px;
}

.markBarcelona {
  text-decoration: line-through #FF0266 ;

}

.markJobs {
  /* text-decoration: line-through #FF0266 ; */
  background: -webkit-gradient(linear,left top,right top,from(#CC5490),to(#FF0266));
  background: linear-gradient(to right, #CC5490,#FF0266);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  font-weight: 200;
}