/* pink color: #FF0266; */
/* original grey from Navbar : #f8f9fa */
/* black color from #212529 */

.projects {
  font-family: 'Poppins', sans-serif;
  max-width: 1300px;
}

.projects h4 {
  text-align: right;
  font-weight: 300;
  padding-top: 15px;
}

.ImageProject {
  /* color: #212529; */
  width: auto;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.frames_UnregelApp {
  background-image: url(./img/wireframes_UnregelApp.jpeg);
  height: 500px;
  background-size: auto;
  background-repeat: no-repeat;
  padding-bottom: 50%;
}

.rowprojects {
    margin-top: 50px;
    margin-bottom: 100px;
}

.mark1 {
  color: #FF0266;
  background-color: transparent;
  font-weight: bold;
  word-break: break-word;
}


.mark2 {
  background-color: #FF0266;
  color: white;
}

.mark1:hover {
  color: #FF0266;
}

.about h1 {
  font-weight: bold;
  color: #FF0266;
}

.empathy {
  padding-top: 40%;
  font-size: 170%;
  font-weight: bold;
  text-align: right;
}

.citation {
  font-size: 100%;
  text-align: right;
  font-weight: 400;
  padding-top: 40px;
  padding-bottom: 40%;
  font-family: 'Crimson Text', serif;
}

.AboutProject {
  font-weight: 200;
}

.AboutProject h6 {
  font-weight: 700;
}

.title {
   padding-top: 20%;
   font-size: 250%;
   text-align: right;
}

 .subtitle {
   text-align: right;
 }


.project2 {
  text-align: left;
}

.subtitol{
  margin-top: 50px;
  margin-bottom: 50px;
}

.subtitol p {
  font-size: 100%;
  font-weight: 300;
  /* margin: 10%; */
}

.subtitol h5 {
  text-align: center;
  font-weight: 400;
  padding-bottom: 15%;
}


/* Starts Image Handa */
.image_persona{
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_persona img {
  width: auto;
  filter: grayscale(40%);

}

/* Ends_Image Handa */

.italic {
  font-style: italic;
}

.table1 {
  font-size: 90%;
  margin-top: 20px;
}

.Abtesting {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

/* Handa User Stories Whole Container Class */

.taula {
  height: 100%;
}

/* Left Column in Handa Section */

.right_column{
}
.right_column h5{
text-align: center; 
margin-top: 20px;
margin-bottom: 20px;

 }

.left_column{
  height: 100%;
}

.left_column h5{
  text-align: center; 
  margin-top: 20px;
  margin-bottom: 20px;
}

/* All Quotes from Handa to make flex */

.alluserstories{
  margin-top: 15px;
  font-family: Times, serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 30%;
  }

/* Quotes from Handa */

.quote {
   color: #FF0266;
   opacity: 0.1;
}

.fa-4x{
  font-size: 40px;
}

.hrsmall {
  width: 100px;
  border-top: 1px solid #FF0266;
}

.userstories1{
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
  border-radius: 15px 50px 30px 5px;
}

.userstories2{
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
}

.userstories3{
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
}

.anchorInvision {
  font-size: 120%;
  font-weight: 400;
}

.AboutProject4 p {
  font-size: 100%;
}

.markbold {
  font-weight: bolder;
}

.gestalt {
  padding-top: 50px;
}
.gestaltgif{
  display: flex;
  justify-content: center;
}

.testing{
  display: flex;
  justify-content: center;
}

h6 {
  margin-top: 30px;
}

.equal h5{
  text-align: center;
}

a {
  color: #FF0266;
}

.markInsights {
  background: -webkit-gradient(linear,left top,right top,from(#CC5490),to(#FF0266));
  background: linear-gradient(to right, #CC5490,#FF0266);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  font-weight: 500;
}
