.navbar {
  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-style: italic;
  padding: .5rem;
}

.navbar-brand {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 5px;
}

.navbar-light .navbar-brand {
  color: #ff0266;
}

.navbar-light .navbar-nav .nav-link {
  color: #ff0266;
  letter-spacing: 2px;
  font-size: 80%;
  border: solid 0.5px;
  border-radius: 300px;
  padding: 0.5rem 0.8rem !important;
  font-style: italic;
  margin-right: 8px;
}

@media (max-width: 576px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 0px;
    padding: 0.5rem !important;

  }
}

.navbar-brand {
  margin-right: 0;
}

.navbar-brand p {
  margin-bottom: 0;
  padding: 0;
  text-align: center;
  margin-left: 10px;
}

.navbar-brand .name {
  font-size: 150%;
}

@media (max-width: 576px) {
  .navbar-brand .name {
    font-size: 120%;
  }

  .navbar-brand p {
    font-size: 120%;
  }
}

.navbar-brand .portfolio {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-style: normal;
  margin-top: -12px;
  margin-left: 17px;
  font-size: 70%;
}

.navbar-text {
  font-style: normal;
  font-size: 80%;
}

.navbar-light a {
  color: #ff0266;
}


.nav-link .buttonmoon {
  background: transparent;
  border: none;
  font-size: 200%;
}

@media (max-width: 576px) {
  .nav-link .buttonmoon {
    padding: 0;
    font-size: 150%;
  }
}

.nav-link {
  padding: 0.5rem;
}


.sun {
  color: gold;
  filter: drop-shadow(2px 2px 2px black);
}

.moon {
  color: gold;
  filter: drop-shadow(2px 2px 2px black);
}

.linkedinbutton {
  font-style: normal;
  color: #ff0266;
}

.nav-link .linkedinbutton a {
  color: #ff0266;
}

/* Start Hover effect in navbar links */
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  background: linear-gradient(to right, #CC5490, #FF0266);
  color: white;
  transition: 1s;
  font-weight: 400;
  /* box-shadow: 0 1px 2px 0 #ff0266, 0 1px 5px 0 #ff0266; */
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #ff0266;
}

/* End Hover effect in navbar links */

.spanblack {
  color: black;
  font-weight: bolder;
}