.form-group {
  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-style: italic;
}

.btn {
  border: solid 2px #FF0266;
  color: #FF0266;
  margin-top: 15px;
  margin-bottom: 7px;
}

.containerform {
  margin-bottom: 50px;
}
