.footer {
  text-align: center;
  font-weight: 200;
  font-style: italic;
  font-family: 'Crimson Text', serif;
  margin-bottom: 30px;
}


.codedwithlove {
  color: #FF0266
}
