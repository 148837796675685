/* Starts Music Section */

.more {
  margin-top: 50px;
  min-height: 450px;
  color: white;
  margin-bottom: 50px;
}


.colleft{
  min-height: 450px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;

}

.colleft h3{
font-size: 1.1rem;
text-align: center;
font-weight: 500;
}

.colright{
color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #252525;
padding: 50px;
min-height: 450px;

}

.colright h3{
  text-align: center;
  font-weight: 200;
  font-size: 1.1rem;
  padding-left: 25px;
  padding-right: 25px;

}

.colright h5{
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.2rem;
}

.spotify {
  display: flex;
  align-items: center;
  margin-top: 30px;
  background-color: #5AB75F;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 3px;
  font-size: 90%;
  padding: 0px 70px;
  min-height: 55px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.59);
}
.spotify:hover{
  color: white;
  text-decoration: none !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.59);
}

.play{
  margin-left: 15px;
}

/* Ends Music Section */
